import CountryBaseController from "./country_base_controller";
import "@andypf/json-viewer";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

const buttonLoadingContent = `<div class="w-full flex flex-col"><span class="font-semibold text-center">Document processing...</span><span class="text-xs text-center">Once the process is complete, the form will be filled in automatically.</span></div>`;

const buttonParsedContent = `<div class="w-full flex flex-col"><span class="font-semibold text-teal-500 text-center">Process finished!</span><span class="text-xs text-center">Form filled. Please check the values.</span></div>`;

function poll(fn, timeout = 30000, interval = 2000) {
  const endTime = Number(new Date()) + timeout;

  async function checkCondition(resolve, reject) {
    try {
      const result = await fn();
      if (result) {
        resolve(result);
      } else if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, interval, resolve, reject);
      } else {
        reject(new Error("request timed out"));
      }
    } catch (error) {
      reject(error);
    }
  }
  return new Promise(checkCondition);
}

const BL_JOB_ID = "palantir_bl_job_id";
const COM_INVOICE_JOB_ID = "palantir_com_invoice_job_id";
const FREIGHT_INVOICE_JOB_ID = "palantir_freight_invoice_job_id";
const DU_JOB_ID = "palantir_du_job_id";

export default class extends CountryBaseController {
  static targets = [
    "blDoc",
    "blDocText",
    "commercialInvoiceDoc",
    "commercialInvoiceDocText",
    "freightInvoiceDoc",
    "freightInvoiceDocText",
    "duDoc",
    "duDocText",
  ];
  static values = {
    apikey: String,
    clientid: String,
    apiurl: String,
    dataBL: Object,
    dataComInvoice: Object,
    dataDU: Object,
    countryName: String,
  };

  connect() {
    console.log("AI Modal Controller Connected");
    this.cn = this.countryNameValue;
    this.palantirBLJobId = this.getPalantirBLJobId();
    this.palantirComInvoiceJobId = this.getPalantirComInvoiceJobId();
    this.palantirFreightInvoiceJobId = this.getPalantirFreightInvoiceJobId();
    this.palantirDUJobId = this.getPalantirDUJobId();
    this.appendJobIdToForm(BL_JOB_ID, this.palantirBLJobId);
    this.appendJobIdToForm(COM_INVOICE_JOB_ID, this.palantirComInvoiceJobId);
    this.appendJobIdToForm(FREIGHT_INVOICE_JOB_ID, this.palantirFreightInvoiceJobId);
    this.appendJobIdToForm(DU_JOB_ID, this.palantirDUJobId);
    const path = location.pathname;
    this.isNewPath = path.includes("/entries/new");
  }

  onFilesChange(event) {
    const attachment = event.params.attachment;

    const loaders = {
      blDoc: {
        loaderId: "blDoc-loader",
        textContainerId: "blDocTextContainer",
        textTarget: this.blDocTextTarget,
        type: "bl",
      },
      commercialInvoiceDoc: {
        loaderId: "commercialInvoiceDoc-loader",
        textContainerId: "commercialInvoiceDocTextContainer",
        textTarget: this.commercialInvoiceDocTextTarget,
        type: "commercial-invoice",
      },
    };
    if (this.hasFreightInvoiceDocTextTarget) {
      loaders.freightInvoiceDoc = {
        loaderId: "freightInvoiceDoc-loader",
        textContainerId: "freightInvoiceDocTextContainer",
        textTarget: this.freightInvoiceDocTextTarget,
        type: "freight-invoice",
      };
    }
    if (this.hasDuDocTextTarget) {
      loaders.duDoc = {
        loaderId: "duDoc-loader",
        textContainerId: "duDocTextContainer",
        textTarget: this.duDocTextTarget,
        type: "du",
      };
    }

    if (loaders[attachment] && this.isNewPath) {
      this.showLoader(loaders[attachment], event.target);
    }
  }

  showLoader({ loaderId, textContainerId, textTarget, type, cb }, fileInput) {
    const loaderElement = document.getElementById(loaderId);
    const textContainerElement = document.getElementById(textContainerId);
    const warning = document.getElementById("invoice-tolerance-warning");
    if (warning && (type === "commercial-invoice" || type === "du")) {
      warning.remove();
    }
    textContainerElement.classList.remove("hidden");
    loaderElement.classList.remove("hidden");
    textTarget.innerHTML = buttonLoadingContent;
    this.submitDocument(fileInput, type)
      .then(() => {
        loaderElement.classList.add("success");
        textTarget.innerHTML = buttonParsedContent;
      })
      .catch((error) => {
        loaderElement.classList.add("error");
        loaderElement.classList.remove("loader");
        textTarget.innerHTML = `
        <span class="text-gray-500 font-semibold">
          ${error.message}
        </span>
        <br/>
        <span class="text-gray-500">
          You can safely ignore this error and continue filling the form manually.
        </span>`;
      });
  }

  getPalantirBLJobId() {
    return this.isNewPath ? null : sessionStorage.getItem(BL_JOB_ID);
  }

  getPalantirComInvoiceJobId() {
    return this.isNewPath ? null : sessionStorage.getItem(COM_INVOICE_JOB_ID);
  }

  getPalantirFreightInvoiceJobId() {
    return this.isNewPath ? null : sessionStorage.getItem(FREIGHT_INVOICE_JOB_ID);
  }

  getPalantirDUJobId() {
    return this.isNewPath ? null : sessionStorage.getItem(DU_JOB_ID);
  }

  appendJobIdToForm(key, jobId = this.getPalantirBLJobId()) {
    window.sessionStorage.setItem(key, jobId);
    if (!jobId) return;

    const form = document.querySelector(
      `form#new_${this.countryNameValue}_entry`,
    );
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = jobId;
    form?.appendChild(input);
  }

  async submitDocument(fileInput, type = "bl") {
    const formData = new FormData();
    formData.append("file", fileInput.files[0]);
    formData.append("country", this.countryNameValue);
    let key = BL_JOB_ID;
    if (type === "bl") {
      key = BL_JOB_ID;
    } else if (type === "commercial-invoice") {
      key = COM_INVOICE_JOB_ID;
    } else if (type === "freight-invoice") {
      key = FREIGHT_INVOICE_JOB_ID;
    } else if (type === "du") {
      key = DU_JOB_ID;
    }
    return fetch(`${this.apiurlValue}/api/parse/${type}`, {
      method: "POST",
      headers: {
        "X-API-KEY": this.apikeyValue,
        "X-CLIENT-ID": this.clientidValue,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`API Error`);
        } else return response.json();
      })
      .then(async ({ data, jobId, status }) => {
        this.appendJobIdToForm(key, jobId);
        if (!data && status?.toLowerCase() === "in_progress") {
          const jobStatus = await poll(async () => {
            return fetch(`${this.apiurlValue}/api/jobs/${jobId}/status`, {
              method: "GET",
              headers: {
                "X-API-KEY": this.apikeyValue,
                "X-CLIENT-ID": this.clientidValue,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.status?.toLowerCase() === "completed" && data.data) {
                  return data;
                } else {
                  return null;
                }
              })
              .catch((err) => {
                console.error(err);
                return null;
              });
          });
          if (type === "bl") {
            this.dataBLValue = jobStatus.data;
            this.fillTheForm();
          } else if (type === "commercial-invoice") {
            this.dataComInvoiceValue = jobStatus.data;
            this.fillInvoiceDetails();
          } else if (type === "freight-invoice") {
            this.dataFreightInvoiceValue = jobStatus.data;
            this.fillFreightInvoiceDetails();
          } else if (type === "du") {
            this.dataDUValue = jobStatus.data;
            this.fillDUDetails();
          }
        }
      })
      .catch((error) => {
        console.error(error);
        throw new Error(`An error occurred while processing the file.`);
      });
  }

  disableButton(target) {
    target.classList.add("disabled");
    target.classList.add("opacity-40");
    target.setAttribute("disabled", "disabled");
  }

  enableButton(target) {
    target.classList.remove("disabled");
    target.classList.remove("opacity-40");
    target.removeAttribute("disabled");
  }

  makeAiIcon() {
    const wrapper = document.createElement("div");
    wrapper.classList.add("flex", "items-center", "justify-end", "flex-1");
    wrapper.setAttribute("palantir-type", "tippy-wrapper");
    const aiIcon = document.createElement("i");
    aiIcon.classList.add("material-icons", "text-teal-500", "cursor-pointer");
    aiIcon.style.textAlign = "right";
    aiIcon.style.fontSize = "1rem";
    aiIcon.textContent = "auto_awesome";
    wrapper.appendChild(aiIcon);
    return { wrapper, aiIcon };
  }

  makePopupContent(value) {
    const wrapper = document.createElement("div");
    const icon = document.createElement("i");
    icon.classList.add(
      "material-icons",
      "text-teal-500",
      "cursor-pointer",
      "ml-2",
    );
    icon.style.textAlign = "right";
    icon.style.fontSize = "1rem";
    icon.textContent = "content_copy";
    icon.style.userSelect = "none";
    wrapper.textContent = value;
    wrapper.appendChild(icon);

    return [wrapper, icon];
  }

  manageInput(selector, data, parent = null, selectFirst = false) {
    const { wrapper, aiIcon } = this.makeAiIcon();

    const target = (parent || document).querySelector(selector);
    if (!target || !data) {
      return;
    }
    const select = target.slim;
    const value =
      typeof data === "object"
        ? "sanitized" in data
          ? data.sanitized
          : data.value
        : data;

    target.value = value ?? data.default ?? "";

    if (value === undefined || value === null) {
      target.classList.add("border-red-500");
      return;
    }

    if (select && !selectFirst) {
      select.search(value);
      setTimeout(() => {
        const v = select
          .getData()
          .find((x) => x.text.toLowerCase() === value.toLowerCase());
        if (v) select.setSelected(v.value);
      }, 2000);
    } else if (select && selectFirst) {
      console.log("Selecting first value");
      select.search(value);
      setTimeout(() => {
        const v = select.getData().find((x) => x.text.includes(value));
        console.log(v);
        if (v) select.setSelected(v.value);
      }, 2000);
    }
    if (
      (target.tagName === "SELECT" || target.nodeName === "SELECT") &&
      !select
    ) {
      // regular select. search among the labels and set the value
      const options = target.querySelectorAll("option");
      options.forEach((option) => {
        if (option.text.toLowerCase() === value.toLowerCase()) {
          option.selected = true;
        }
      });
    }

    if (target.getAttribute("data-datepicker-target")) {
      flatpickr(target, {
        dateFormat: "d-m-Y",
        defaultDate: typeof value === "string" ? new Date(value) : value,
      });
    }

    // Add AI Icon next to the label
    const label = (
      !!select ? target.parentNode.parentNode : target.parentNode
    ).querySelector(!!select ? "legend" : "label");
    label?.classList.add("flex");
    label?.querySelector("[palantir-type=tippy-wrapper]")?.remove();
    label?.appendChild(wrapper);

    // crate a popup content for the AI Icon
    const [popupContent, icon] = this.makePopupContent(value);
    icon.addEventListener("click", (e) => {
      e.stopPropagation();
      navigator.clipboard.writeText(value);
      e.target.textContent = "check_circle_outline";
      setTimeout(() => {
        e.target.textContent = "content_copy";
      }, 1000);
    });

    tippy(aiIcon, {
      content: popupContent,
      trigger: "click",
      allowHTML: true,
      interactive: true,
      inertia: true,
    });

    const borderTarget = !!select ? target.parentNode : target;
    borderTarget.classList.add("border", "rounded");
    // Add border color based on confidence
    if (data.confidence === 1.0) {
      borderTarget.classList.add("border-green-500");
    } else if (data.confidence < 1.0 && data.confidence >= 0.75) {
      borderTarget.classList.add("border-lime-500");
    } else if (data.confidence < 0.75 && data.confidence >= 0.5) {
      borderTarget.classList.add("border-yellow-500");
    } else if (data.confidence < 0.5 && data.confidence > 0.1) {
      borderTarget.classList.add("border-yellow-700");
    } else {
      borderTarget.classList.add("border-red-500");
    }

    target.addEventListener("input", function () {
      borderTarget.classList.remove(
        "border-red-500",
        "border-green-500",
        "border-yellow-500",
        "border-yellow-700",
        "border-lime-500",
      );
    });
  }

  fillTheForm() {
    this.fillBlNumber();
    this.selectPassiveUser();
    this.fillShipperSection();
    this.fillConsigneeSection();
    this.fillNotifySection();
    this.fillBLSection();
    this.fillShipmentDetails();
  }

  fillBlNumber() {
    this.manageInput(
      `#${this.cn}_entry_bl_number`,
      this.dataBLValue.bill_of_lading_number,
    );
  }

  // Select the passive user as "SCK" for operators
  selectPassiveUser() {
    this.manageInput(`#${this.cn}_entry_passive_user_id`, "SCK - SCK");
  }

  toleranceCheckForInvoiceValue() {
    const invoiceValue = this.dataComInvoiceValue.invoice_value;
    let duValue = this.dataDUValue.value;
    if (typeof duValue === "string") {
      duValue = parseFloat(this.dataDUValue.value.replace('.', '').replace(',', '.'))
    }
    if (invoiceValue) {
      const diff = (Math.abs(duValue - invoiceValue) / duValue) * 100;
      if (diff > 5) {
        const commercialInvoiceDocTextContainer = document.getElementById("commercialInvoiceDocTextContainer");
        const warning = document.createElement("p");
        warning.id = "invoice-tolerance-warning";
        warning.textContent = "The invoice value violates the ±5% tolerance limit stated in the DU document.";
        warning.classList.add("block", "text-red-500", "font-semibold", "text-xs", "text-center");
        commercialInvoiceDocTextContainer.appendChild(warning);
      }
    }
  }

  fillDUDetails() {
    this.manageInput(`#${this.cn}_entry_du_number`, this.dataDUValue.du_number);
    this.manageInput(`#${this.cn}_entry_nif_no`, this.dataDUValue.nif_number);
    this.fillGoods("du");
    this.toleranceCheckForInvoiceValue();
  }

  fillFreightInvoiceDetails() {
    this.manageInput(`#${this.cn}_entry_currency_of_freight`, this.dataFreightInvoiceValue.currency);
    this.manageInput(`#${this.cn}_entry_freight_value`, this.dataFreightInvoiceValue.freight_value);
    this.manageInput(`#${this.cn}_entry_fob_value`, this.dataFreightInvoiceValue.fob_value);
    this.manageInput(`#${this.cn}_entry_total_gross_weight`, this.dataFreightInvoiceValue.total_gross_weight);
    this.manageInput(`#${this.cn}_entry_total_gross_weight`, this.dataFreightInvoiceValue.total_gross_weight);
    this.manageInput(`#${this.cn}_entry_volume`, this.dataFreightInvoiceValue.volume);
    this.fillGoods("freight-invoice");
  }

  fillInvoiceDetails() {
    this.toleranceCheckForInvoiceValue();

    this.manageInput(
      `#${this.cn}_entry_invoice_number`,
      this.dataComInvoiceValue.invoice_number,
    );
    this.manageInput(
      `#${this.cn}_entry_incoterm`,
      this.dataComInvoiceValue.incoterm,
    );
    this.manageInput(
      `#${this.cn}_entry_currency`,
      this.dataComInvoiceValue.currency,
    );
    if (this.cn === "somalia" || this.cn === "djibouti") {
      this.manageInput(
        `#${this.cn}_entry_currency_of_goods`,
        this.dataComInvoiceValue.currency_of_goods,
      );
      this.manageInput(
        `#${this.cn}_entry_currency_of_insurance`,
        this.dataComInvoiceValue.currency_of_insurance,
      );
      this.manageInput(
        `#${this.cn}_entry_currency_of_freight`,
        this.dataComInvoiceValue.currency_of_freight,
      );
      this.manageInput(
        `#${this.cn}_entry_currency_of_other_charges`,
        this.dataComInvoiceValue.currency_of_other_charges,
      );
    }
    this.manageInput(
      `#${this.cn}_entry_fob_value`,
      this.dataComInvoiceValue.fob_value,
    );
    this.manageInput(
      `#${this.cn}_entry_invoice_value`,
      this.dataComInvoiceValue.invoice_value,
    );
    this.manageInput(
      `#${this.cn}_entry_insurance_value`,
      this.dataComInvoiceValue.insurance_value,
    );
    this.manageInput(
      `#${this.cn}_entry_ocean_freight`,
      this.dataComInvoiceValue.ocean_freight,
    );
    this.fillGoods("commercial-invoice");
  }

  fillShipperSection() {
    if (this.cn === "sierra_leone") {
      this.manageInput(`#${this.cn}_entry_shipper_attributes_name`, {
        value: `${this.dataBLValue.shipper_name.value} ${this.dataBLValue.shipper_address.sanitized}`,
      });
    } else {
      this.manageInput(
        `#${this.cn}_entry_shipper_attributes_name`,
        this.dataBLValue.shipper_name,
      );
    }
    this.manageInput(`#${this.cn}_entry_shipper_attributes_email`, {
      ...this.dataBLValue.shipper_email,
      default: "noreply@example.com",
    });
    this.manageInput(`#${this.cn}_entry_shipper_attributes_phone`, {
      ...this.dataBLValue.shipper_phone,
      default: "0",
    });
    this.manageInput(
      `#${this.cn}_entry_shipper_attributes_postal_code`,
      { ...this.dataBLValue.shipper_postal_code, default: "0" },
    );
    this.manageInput(
      `#${this.cn}_entry_shipper_attributes_address`,
      this.dataBLValue.shipper_address,
    );
    const shipperEl = this.findInputId(
      `${this.cn}_entry_shipper_attributes_country`,
      "shipper_country",
    );
    this.manageInput(`#${shipperEl}`, this.dataBLValue.shipper_country);
    setTimeout(() => {
      this.manageInput(
        `#${this.findInputId(`${this.cn}_entry_shipper_attributes_city`)}`,
        this.dataBLValue.shipper_city,
      );
    }, 2000);
  }

  fillConsigneeSection() {
    if (this.cn === "sierra_leone") {
      this.manageInput(`#${this.cn}_entry_consignee_attributes_name`, {
        value: `${this.dataBLValue.consignee_name.value} ${this.dataBLValue.consignee_address.sanitized}`,
      });
    } else {
      this.manageInput(
        `#${this.cn}_entry_consignee_attributes_name`,
        this.dataBLValue.consignee_name,
      );
    }
    this.manageInput(`#${this.cn}_entry_consignee_attributes_email`, {
      ...this.dataBLValue.consignee_email,
      default: "noreply@example.com",
    });
    this.manageInput(`#${this.cn}_entry_consignee_attributes_phone`, {
      ...this.dataBLValue.consignee_phone,
      default: "0",
    });
    this.manageInput(
      `#${this.cn}_entry_consignee_attributes_postal_code`,
      { ...this.dataBLValue.consignee_postal_code, default: "0" },
    );
    this.manageInput(
      `#${this.cn}_entry_consignee_attributes_address`,
      this.dataBLValue.consignee_address,
    );

    const countryEl = this.findInputId(
      `${this.cn}_entry_consignee_attributes_country`,
      "consignee_country",
    );
    this.manageInput(`#${countryEl}`, this.dataBLValue.consignee_country);

    setTimeout(() => {
      this.manageInput(
        `#${this.findInputId(`${this.cn}_entry_consignee_attributes_city`)}`,
        this.dataBLValue.consignee_city,
      );
    }, 2000);
  }

  fillNotifySection() {
    this.manageInput(
      `#${this.cn}_entry_notify_attributes_name`,
      this.dataBLValue.notify_name,
    );
    this.manageInput(`#${this.cn}_entry_notify_attributes_email`, {
      ...this.dataBLValue.notify_email,
      default: "noreply@example.com",
    });
    this.manageInput(`#${this.cn}_entry_notify_attributes_phone`, {
      ...this.dataBLValue.notify_phone,
      default: "0",
    });
    this.manageInput(
      `#${this.cn}_entry_notify_attributes_postal_code`,
      { ...this.dataBLValue.notify_postal_code, default: "0" },
    );
    this.manageInput(
      `#${this.cn}_entry_notify_attributes_address`,
      this.dataBLValue.notify_address,
    );
    const notifyEl = this.findInputId(
      `${this.cn}_entry_notify_attributes_country`,
      "notify_country",
    );
    this.manageInput(`#${notifyEl}`, this.dataBLValue.notify_country);
    setTimeout(() => {
      this.manageInput(
        `#${this.findInputId(`${this.cn}_entry_notify_attributes_city`)}`,
        this.dataBLValue.notify_city,
      );
    }, 2000);
  }

  fillBLSection() {
    if (this.cn === "sierra_leone") {
      this.manageInput(
        `#${this.cn}_entry_booking_number`,
        this.dataBLValue.bill_of_lading_number,
      );
    }
    this.manageInput(
      `#${this.cn}_entry_voyage_number`,
      this.dataBLValue.voyage_number,
    );
    this.manageInput(
      `#${this.cn}_entry_taxpayer_number`,
      this.dataBLValue.taxpayer_number,
    );
    this.manageInput(
      this.cn === "sierra_leone"
        ? `${this.cn}_entry_loading_country`
        : "#country_of_loading",
      this.dataBLValue.country_of_loading,
    );

    setTimeout(() => {
      this.manageInput(
        this.cn === "sierra_leone"
          ? `#${this.cn}_entry_loding_city`
          : `#${this.cn}_entry_port_of_loading_id`,
        this.dataBLValue.port_of_loading,
      );
    }, 2000);

    this.manageInput(
      this.cn === "sierra_leone"
        ? `#${this.cn}_entry_discharge_city`
        : `#${this.cn}_entry_port_of_discharge_id`,
      this.dataBLValue.port_of_discharge,
    );

    this.manageInput(
      this.cn === "sierra_leone"
        ? `#${this.cn}_entry_vessel`
        : `#${this.cn}_entry_vessel_id`,
      this.dataBLValue.vessel,
    );

    this.manageInput(
      `#${this.cn}_entry_final_destination`,
      this.dataBLValue.place_of_delivery || this.dataBLValue.consignee_city,
    );

    this.manageInput(`#${this.cn}_entry_eta`, {
      value: new Date(
        new Date().setMonth(new Date().getMonth() + 1),
      ).toISOString(),
    });

    this.manageInput(
      `#${this.cn}_entry_line_id`,
      this.dataBLValue.shipping_line,
    );

    this.manageInput(
      `#${this.cn}_entry_carrier_id`,
      this.dataBLValue.shipping_line,
    );
  }

  fillShipmentDetails() {
    this.manageInput(`#${this.cn}_entry_volume`, {
      ...this.dataBLValue.total_measurement,
      default: 0,
    });
    this.manageInput(
      `#${this.cn}_entry_description_of_goods`,
      this.dataBLValue.description_of_goods ||
      this.dataBLValue.containers?.[0]?.description_of_goods,
    );
    this.manageInput(
      `#${this.cn}_entry_total_gross_weight`,
      this.dataBLValue.total_weight,
    );
    this.manageInput(
      `#${this.cn}_entry_number_of_packages`,
      this.dataBLValue.number_of_packages,
    );

    const shipmentType = this.predictShipmentType(this.dataBLValue);
    this.manageInput("#shipment_type", {
      value: shipmentType,
    });

    this.manageInput(`#${this.cn}_entry_freight_payment`, {
      ...(this.dataBLValue.freight_payment || { value: "Prepaid" }),
      default: "Prepaid",
    });

    setTimeout(() => {
      const addBtn = document.querySelector(
        "[data-shipment-target=box] [data-palantir-type=add-row]",
      );
      if (!addBtn) return;

      if (shipmentType === "container") {
        for (let i = 0; i < this.dataBLValue.containers.length; i++) {
          addBtn.click();
        }
        setTimeout(() => {
          document
            .querySelectorAll(
              "[data-shipment-target=box] [data-palantir-type=container-row]",
            )
            .forEach((box, i) => {
              this.manageInput(
                '[data-palantir-type="container_number"]',
                this.dataBLValue.containers[i].container_no,
                box,
              );
              this.manageInput(
                '[data-palantir-type="seal_number"]',
                this.dataBLValue.containers[i].seal_no,
                box,
              );
              this.manageInput(
                '[data-palantir-type="container_size"]',
                this.dataBLValue.containers[i].container_size,
                box,
              );
              this.manageInput(
                '[data-palantir-type="container_type"]',
                this.dataBLValue.containers[i].container_type,
                box,
              );
              this.manageInput(
                '[data-palantir-type="container_load_type"]',
                this.dataBLValue.containers[i].load_type,
                box,
              );
            });
        }, 500);
      } else if (shipmentType === "roro") {
        for (let i = 0; i < this.dataBLValue.roro?.length; i++) {
          addBtn.click();
        }
      } else {
        addBtn.click();
      }
    }, 500);
  }

  predictShipmentType(data) {
    if (data.containers?.length > 0) return "container";
    if (data.roro?.length > 0) return "roro";
    if (data.genco) return "genco";
    if (data.bulk) return "bulk";
    return "unknown";
  }

  fillGoods(type = "") {
    const country = this.countryNameValue;
    const addBtn = document.querySelector(
      "[data-assoctype=goods][data-palantir-type=add-row]",
    )

    let count = 0

    if (country === "angola") {
      count = this.dataDUValue.entries.length;
    } else if ((country === "djibouti" || country === "somalia") && type === "freight-invoice") {
      count = this.dataFreightInvoiceValue.products.length - 1;
    } else if ((country === "djibouti" || country === "somalia") && type === "commercial-invoice") {
      count = this.dataComInvoiceValue.products.length - 1;
    } else if (country === "cameroon" && type === "freight-invoice") {
      count = this.dataFreightInvoiceValue.products.length - 1;
    } else if (country === "cameroon" && type === "commercial-invoice") {
      count = this.dataComInvoiceValue.products.length - 1;
    } else if (country === "benin" && type === "commercial-invoice") {
      count = this.dataComInvoiceValue.products.length;
    } else if (country === "benin" && type === "freight-invoice") {
      count = this.dataFreightInvoiceValue.products.length;
    }


    if (!addBtn) return;
    for (let i = 0; i < count; i++) {
      addBtn.click();
    }
    if (country === "angola") {
      setTimeout(() => {
        document
          .querySelectorAll(
            ".assoc-good-row",
          )
          .forEach((box, i) => {
            this.manageInput(
              '[data-palantir-type="good_description"]',
              this.dataDUValue.entries[i].description,
              box,
            );
            this.manageInput(
              '[data-palantir-type="gross_weight"]',
              this.dataDUValue.entries[i].net_weight,
              box,
            );
            this.manageInput(
              '[data-palantir-type="ocean_freight"]',
              this.dataDUValue.entries[i].freight,
              box,
            );
            this.manageInput(
              '[data-palantir-type="fob_value"]',
              this.dataDUValue.entries[i].fob_value,
              box,
            );
            this.manageInput(
              '[data-palantir-type="number_of_packages"]',
              this.dataDUValue.entries[i].quantity,
              box,
            );
            this.manageInput(
              '[data-palantir-type="hs_code"]',
              this.dataDUValue.entries[i].hs_code,
              box,
              true,
            );
          });
      }, 500);
    } else if ((country === "djibouti" || country === "somalia") && type === "freight-invoice") {
      setTimeout(() => {
        document
          .querySelectorAll(
            ".assoc-good-row",
          )
          .forEach((box, i) => {
            this.manageInput(
              '[data-palantir-type="hs_code"]',
              this.dataFreightInvoiceValue.products[i].hs_code,
              box,
              true,
            );
            this.manageInput(
              '[data-palantir-type="origin_of_goods"]',
              this.dataFreightInvoiceValue.products[i].origin_country,
              box,
            );
            this.manageInput(
              '[data-palantir-type="number_of_packages"]',
              this.dataFreightInvoiceValue.products[i].quantity,
              box,
            );
            this.manageInput(
              '[data-palantir-type="package_type"]',
              this.dataFreightInvoiceValue.products[i].package_type,
              box,
            );
          });
      }, 500);
    } else if ((country === "djibouti" || country === "somalia") && type === "commercial-invoice") {
      setTimeout(() => {
        document
          .querySelectorAll(
            ".assoc-good-row",
          )
          .forEach((box, i) => {
            this.manageInput(
              '[data-palantir-type="hs_code"]',
              this.dataComInvoiceValue.products[i].hs_code,
              box,
              true,
            );
            this.manageInput(
              '[data-palantir-type="origin_of_goods"]',
              this.dataComInvoiceValue.products[i].origin_country,
              box,
            );
            this.manageInput(
              '[data-palantir-type="number_of_packages"]',
              this.dataComInvoiceValue.products[i].quantity,
              box,
            );
            this.manageInput(
              '[data-palantir-type="package_type"]',
              this.dataComInvoiceValue.products[i].package_type,
              box,
            );
          });
      }, 500);
    } else if (country === "cameroon" && type === "freight-invoice") {
      setTimeout(() => {
        document
          .querySelectorAll(
            ".assoc-good-row",
          )
          .forEach((box, i) => {
            this.manageInput(
              '[data-palantir-type="currency"]',
              this.dataFreightInvoiceValue.currency,
              box,
            );
            this.manageInput(
              '[data-palantir-type="hs_code"]',
              this.dataFreightInvoiceValue.products[i].hs_code,
              box,
              true,
            );
            this.manageInput(
              '[data-palantir-type="product_name"]',
              this.dataFreightInvoiceValue.products[i].description,
              box,
            );
            this.manageInput(
              '[data-palantir-type="goods_value"]',
              this.dataFreightInvoiceValue.products[i].total_value,
              box,
            );
            this.manageInput(
              '[data-palantir-type="weight"]',
              this.dataFreightInvoiceValue.products[i].weight,
              box,
            );
          });
      }, 500);
    } else if (country === "cameroon" && type === "commercial-invoice") {
      setTimeout(() => {
        document
          .querySelectorAll(
            ".assoc-good-row",
          )
          .forEach((box, i) => {
            this.manageInput(
              '[data-palantir-type="currency"]',
              this.dataComInvoiceValue.currency,
              box,
            );
            this.manageInput(
              '[data-palantir-type="hs_code"]',
              this.dataComInvoiceValue.products[i].hs_code,
              box,
              true,
            );
            this.manageInput(
              '[data-palantir-type="product_name"]',
              this.dataComInvoiceValue.products[i].description,
              box,
            );
            this.manageInput(
              '[data-palantir-type="goods_value"]',
              this.dataComInvoiceValue.products[i].total_value,
              box,
            );
            this.manageInput(
              '[data-palantir-type="weight"]',
              this.dataComInvoiceValue.products[i].weight,
              box,
            );
          });
      }, 500);
    } else if (country === "benin" && type === "commercial-invoice") {
      setTimeout(() => {
        document
          .querySelectorAll(
            ".assoc-good-row",
          )
          .forEach((box, i) => {
            this.manageInput(
              '[data-palantir-type="good_description"]',
              this.dataComInvoiceValue.products[i].description,
              box,
            );
            this.manageInput(
              '[data-palantir-type="hs_code"]',
              this.dataComInvoiceValue.products[i].hs_code,
              box,
              true,
            );
            this.manageInput(
              '[data-palantir-type="cargo_type"]',
              this.dataComInvoiceValue.products[i].package_type,
              box,
            );
            this.manageInput(
              '[data-palantir-type="gross_weight"]',
              this.dataComInvoiceValue.products[i].weight,
              box,
            );
            this.manageInput(
              '[data-palantir-type="fob_value"]',
              this.dataComInvoiceValue.fob_value,
              box,
            );
            this.manageInput(
              '[data-palantir-type="ocean_freight"]',
              this.dataComInvoiceValue.ocean_freight,
              box,
            );
          });
      }, 500);
    } else if (country === "benin" && type === "freight-invoice") {
      setTimeout(() => {
        document
          .querySelectorAll(
            ".assoc-good-row",
          )
          .forEach((box, i) => {
            this.manageInput(
              '[data-palantir-type="good_description"]',
              this.dataFreightInvoiceValue.products[i].description,
              box,
            );
            this.manageInput(
              '[data-palantir-type="hs_code"]',
              this.dataFreightInvoiceValue.products[i].hs_code,
              box,
              true,
            );
            this.manageInput(
              '[data-palantir-type="cargo_type"]',
              this.dataFreightInvoiceValue.products[i].package_type,
              box,
            );
            this.manageInput(
              '[data-palantir-type="gross_weight"]',
              this.dataFreightInvoiceValue.products[i].weight,
              box,
            );
            this.manageInput(
              '[data-palantir-type="fob_value"]',
              this.dataFreightInvoiceValue.total_fob_value / this.dataFreightInvoiceValue.products.length,
              box,
            );
            this.manageInput(
              '[data-palantir-type="ocean_freight"]',
              this.dataFreightInvoiceValue.freight_value / this.dataFreightInvoiceValue.products.length,
              box,
            );
          });
      }, 500);
    }
  }

  findInputId(_selector, fallback = null) {
    const selector = _selector.replace("#", "");
    const dqs = document.getElementById.bind(document);
    if (dqs(selector)) return selector;
    else if (dqs(`${selector}_id`)) return `${selector}_id`;
    else return fallback;
  }
}
